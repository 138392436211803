<template>
  <div class="navigation">
    <div id="navigation">
      <router-link class="section left" to="/">
        <img class="logo" src="/images/icon.png" alt="Stactix Logo" width="40" height="40" />
        <h1>Stactix</h1>
      </router-link>
      <div class="section main">
        <router-link v-if="user !== null" to="/">Dashboard</router-link>
        <router-link v-if="user !== null" to="/map">Map</router-link>
      </div>
      <div class="section right" v-if="user !== null">
        <router-link
          class="settings"
          @click.native="pushSettings"
          to="/settings"
          >Einstellungen
        </router-link>
        <a v-on:click="logout()">Logout</a>
      </div>
      <div v-else class="section right">
        <router-link to="/login">Login</router-link>
      </div>
    </div>
    <div id="mobile-navigation">
      <router-link class="section left" to="/">
        <img class="logo" src="/images/icon.png" alt="Stactix Logo" width="40" height="40" />
      </router-link>
      <div class="section right">
        <div id="hamburger" v-on:click="opened = !opened">
          <span :class="{ open: opened }"></span>
          <span :class="{ open: opened }"></span>
          <span :class="{ open: opened }"></span>
        </div>
      </div>
      <div class="drawer" :class="{ showmenu: opened }">
        <ul v-if="user !== null">
          <li v-on:click="opened = false"><router-link  to="/">Dashboard</router-link></li>
          <li v-on:click="opened = false"><router-link to="/map">Map</router-link></li>
          <li v-on:click="opened = false">
            <router-link to="/settings" @click.native="pushSettings"
              >Einstellungen</router-link
            >
          </li>
          <li v-on:click="opened = false"><a href="#" v-on:click="logout()">Logout</a></li>
        </ul>
        <ul v-else>
          <li v-on:click="opened = false"><router-link to="/login">Login</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "../utils/firebase";

export default {
  name: "Navbar",
  data() {
    return {
      user: null,
      opened: false,
    };
  },
  computed: {},
  methods: {
    logout() {
      this.opened = false;
      this.$swal({
        title: "Willst du dich wirklich ausloggen?",
        icon: "question",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("logout");
        }
      });
    },
    pushSettings() {
      this.$store.commit("setSettingsState", "profil");
    },
  },
  created: function () {
    let vm = this;

    auth.onAuthStateChanged(function (user) {
      if (user) {
        vm.user = user;
      } else {
        vm.user = null;
      }
    });
  },
};
</script>

<style lang="less" scoped>
#mobile-navigation {
  display: none;
}
#navigation {
  padding: 0 2rem 0 2rem;
  height: 60px;
  box-shadow: 0 3px 3px -2px rgba(102, 75, 75, 0.6);
  z-index: 10;
  position: absolute;
  width: 100vw;
  background-color: rgb(255, 143, 158);
  color: white;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  font-family: "Helvetica";
  .section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .left {
    color: white;
    text-decoration: none;
  }
  .main,
  .right {
    justify-content: flex-end;

    a {
      padding: 1rem;
      text-decoration: none;
      font-size: 1.2rem;
      color: white;
      &.router-link-exact-active {
        text-decoration: underline;
        text-underline-offset: 10px;
      }
      &:hover {
        text-decoration: underline;
        text-underline-offset: 10px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #navigation {
    display: none;
  }
  #mobile-navigation {
    background-color: rgb(255, 143, 158);
    height: 60px;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: fixed;
    width: 100vw;
    padding: 0 0.25rem;
    .section {
      a {
        padding: 1rem 0.25rem;
      }
      &.left {
        display: flex;
        justify-content: flex-start;
        margin-left: 0.5rem;
        padding: 0.5rem;
      }
      &.right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 1rem;
        padding: 0.5rem;
        #hamburger {
          display: flex;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          span {
            background-color: white;
            width: 2em;
            height: 0.2em;
            margin: 0.26em 0;
            display: block;
            transition: all 0.4s ease;
            transform-origin: 0 0;
          }
          .open:nth-child(1) {
            transform: rotate(45deg) translate(1px, -1px);
          }
          .open:nth-child(2) {
            transform: scaleX(0);
          }
          .open:nth-child(3) {
            transform: rotate(-45deg) translate(1px, 0);
          }
        }
      }
    }
    .drawer {
      position: absolute;
      top: 60px;
      visibility: hidden;
      height: 0;
      background-color: rgb(255, 143, 158);
      width: 100vw;
      transition: height 0.4s ease-out;
      z-index: 20;
      ul {
        padding: 0;
        li {
          padding: 1rem;
          a {
            color: white;
            text-decoration: none;
            font-size: 2rem;
            opacity: 0;
            &.router-link-exact-active {
            text-decoration: underline;
            text-underline-offset: 10px;
      }
          }
        }
      }
      &.showmenu {
        visibility: visible;
        height: calc(100vh - 60px);
        transition-delay: 0.1;
        transition: height 0.35s ease-out;
        ul {
          li a {
            opacity: 1;
          }
          li:nth-child(1) a {
            transition: opacity 0.5s ease-in;
          }
          li:nth-child(2) a {
            transition: opacity 0.5s ease-in 200ms;
          }
          li:nth-child(3) a {
            transition: opacity 0.5s ease-in 200ms;
          }
          li:nth-child(4) a {
            transition: opacity 0.5s ease-in 200ms;
          }
        }
      }
    }
  }
}
</style>
