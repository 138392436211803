import * as fb from '../../utils/firebase'
import { v4 as uuidv4 } from 'uuid';
import campaign from './campaign'

export default{
    namespaced: true,
    modules:{
        campaign,
    },
    state:{
        userOrgas: [],
        selectedOrga: {user: false, orga: false},
        orgaMember: [],
        orgaMemberListenerUnsub: null,
        selOrgaListenerUnsub:null,
        userOrgasListener: false,
    },
    mutations:{
        setUserOrgas(state, val) {
            state.userOrgas = val;
        },
        setSelectedOrga(state, val){
            state.selectedOrga = val;
        },
        setOrgaMember(state, val){
            state.orgaMember = val;
        },
        setUserOrgasListener(state, val){
            state.userOrgasListener = val;
        },
        setOrgaMemberListener(state, val){
            state.orgaMemberListenerUnsub = val;
        },
        setSelOrgaListener(state, val){
            state.selOrgaListenerUnsub = val;
        },
        resetOrgaMemberListener(state){
            state.orgaMemberListenerUnsub();
        },
        resetSelOrgaListener(state){
            state.selOrgaListenerUnsub();
        },
        resetUserOrgasListener(state){
            state.userOrgasListener();
        }
    },
    getters: {
        userOrgas: state => {
            return state.userOrgas;
        },
        selectedOrga: state => {
            return state.selectedOrga;
        },
        orgaMember: state => {
            return state.orgaMember;
        },
        userOrgaListener: state => {
            return state.userOrgasListener;
        }
    },
    actions:{
        getUserOrgas({commit, rootGetters}) {
            const unsubscribe = fb.userCollection.doc(fb.auth.currentUser.uid).collection("organization").onSnapshot(snapshot => {
            let orgasArray = [];
              snapshot.forEach(doc => {
                let orga = doc.data();
                orga.id = doc.id;
                orgasArray.push(orga);
              })
              commit('setUserOrgas', orgasArray);
              if(orgasArray.length < 1){
                  let user = rootGetters['user/user'];
                  let selectedOrgaTuple = {
                      user: user,
                      orga: false,
                    }
                commit('setSelectedOrga', selectedOrgaTuple);
              }
            });
            commit('setUserOrgasListener', unsubscribe);
        },
        initGetSelectedOrga({rootGetters, commit, dispatch}, orga){
            const user = rootGetters['user/user'];
            fb.organizationCollection.doc(orga.id).get().then((snapshot) => {
                let orgaData = snapshot.data();
                orgaData.id = snapshot.id;
                let selectedOrgaTuple = {
                    user: user,
                    orga: orgaData,
                }
                commit('setSelectedOrga', selectedOrgaTuple);
                dispatch('getSelectedOrga', orga);
                dispatch('campaign/getUserCampaigns'); 
            });
            dispatch('getOrgaMembers', orga);
        },
        getSelectedOrga({rootGetters, commit}, orga){
            const user = rootGetters['user/user'];
            const unsubscribe = fb.organizationCollection.doc(orga.id).onSnapshot(snapshot => {
                let orgaData = snapshot.data();
                orgaData.id = snapshot.id;
                let selectedOrgaTuple = {
                    user: user,
                    orga: orgaData,
                }
                commit('setSelectedOrga', selectedOrgaTuple);
            });
            commit('setSelOrgaListener', unsubscribe);  
        },
        getOrgaMembers({commit}, orga){
            const unsubscribe = fb.organizationCollection.doc(orga.id).collection("user").onSnapshot(snapshot => {
                let orgaMemberArray = [];
                snapshot.forEach(doc => {
                    let member = doc.data();
                    member.id = doc.id;
                    orgaMemberArray.push(member);
                });
                commit('setOrgaMember', orgaMemberArray);
            });
            commit('setOrgaMemberListener', unsubscribe);
        },
        async createOrga({rootGetters}, orgaUpdate){
            let user = rootGetters['user/user'];
            let newOrgaUid = uuidv4();
            orgaUpdate.id = newOrgaUid;
            try{
                await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.userCollection.doc(user.id))
                    .then(() => {
                        transaction.set(fb.organizationCollection.doc(newOrgaUid), {
                            creator: user.nickname,
                            location: orgaUpdate.location,
                            name: orgaUpdate.name,
                            party: orgaUpdate.party,
                        });
                    });
                }).then(() => {
                    /*let selectedOrgaTuple = {
                        user: user,
                        orga: false,
                    }
                    commit('setSelectedOrga', selectedOrgaTuple);*/
                    return 1;
                });
            } catch(e){
                console.log(e);
                return -1;
            }
        },
        async inviteUser({getters}, nickname){
            let selectedOrga = getters['selectedOrga'];
            try{
                return await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.nicknameCollection.doc(nickname))
                    .then((doc) => {
                        let userInvID = doc.data().uid;
                        transaction.set(fb.organizationCollection.doc(selectedOrga.orga.id).collection('user').doc(userInvID), {
                            nickname: nickname,
                            role: 'member',
                        });
                    });
                }).then(() => {
                    return 1;
                });
            } catch(e){
                console.log(e);
                return -1;
            } 
        },
        async deleteOrga({commit, rootGetters}, orga){
            let user = rootGetters['user/user'];
            try{
                return await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(orga.id))
                    .then(() => {
                        transaction.delete(fb.organizationCollection.doc(orga.id));
                    });
                }).then(() => {
                    commit('setSelectedOrga', {user: user, orga: false});
                    commit('campaign/setSelectedCampaign', {orga: false, campaign: false})
                    return 1;
                });
            }catch(e){
                console.log(e);
                return -1;
            }
        },
        async leaveOrga({commit, rootGetters}, orga){
            let user = rootGetters['user/user'];
            try{
                return await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.userCollection.doc(user.id).collection('organization').doc(orga.id))
                    .then(() => {
                        transaction.delete(fb.userCollection.doc(user.id).collection('organization').doc(orga.id));
                    });
                }).then(() => {
                    commit('setSelectedOrga', {user: false, orga: false});
                    commit('campaign/setSelectedCampaign', {orga: false, campaign: false})
                    return 1;
                });
            }catch(e){
                console.log(e);
                return -1;
            }
        },
        async update({getters},orgaUpdate){
            // Wenn kein Getter genutzt wird dann hier Fehler ?? ...
            let orgaTuple = getters['selectedOrga'];
            try{
                return await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(orgaTuple.orga.id))
                    .then(() => {
                        transaction.update(fb.organizationCollection.doc(orgaTuple.orga.id), {
                            location: orgaUpdate.location,
                            name: orgaUpdate.name,
                            party: orgaUpdate.party,
                        });
                    });
                }).then(() => {
                    return 1;
                });
            }catch(e){
                console.log(e);
                return -1;
            }
        },
        async updateMember({getters}, member){
            let orgaTuple = getters['selectedOrga'];
            try{
                return await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(orgaTuple.orga.id))
                    .then(() => {
                        transaction.update(fb.organizationCollection.doc(orgaTuple.orga.id)
                        .collection('user').doc(member.id), {
                            role: member.role
                        });
                    }).then(() =>{
                        return 1;
                    });
                });
            }catch(e){
                console.log(e);
                return -1;
            }

        },
        async deleteUser({getters}, member){
            let orgaTuple = getters['selectedOrga'];
            try{
                return await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(orgaTuple.orga.id))
                    .then(() => {
                        transaction.delete(fb.organizationCollection.doc(orgaTuple.orga.id)
                        .collection('user').doc(member.id));
                    }).then(() =>{
                        return 1;
                    });
                });
            }catch(e){
                console.log(e);
                return -1;
            }
        },
    },
};