<template>
  <div class="register content">
    <div class="container">
      <v-form class="login-form" @submit.prevent="signup">
        <h2>Registrieren</h2>
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.email.$error }"
        >
          <label for="email">
            <v-text-field
              v-model="email"
              name="email"
              type="email"
              label="E-Mail"
              :error-messages="mailError"
            />
          </label>
        </div>
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.password.$error }"
        >
          <label for="password">
            <v-text-field
              v-model="password"
              name="password"
              type="password"
              label="Passwort"
              :error-messages="passwordError"
            />
          </label>
        </div>
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.password_repeat.$error }"
        >
          <label for="password-repeat">
            <v-text-field
              v-model="password_repeat"
              name="password-repeat"
              type="password"
              label="Passwort wiederholen"
              :error-messages="passwordRepeatError"
            />
          </label>
        </div>
        <div class="privacy">
          <input type="checkbox" v-model="agreement" />
          <p>
            Ich stimme den
            <a href="#" @click.stop.prevent="dialog = true">AGB</a>
            und den
            <a href="#" @click.stop.prevent="dialog = true"
              >Datenschutzbestimmungen</a
            >
            zu.
          </p>
        </div>
        <v-btn rounded type="submit" :loading="loading" :disabled="loading"> Registrieren </v-btn>
      </v-form>
      <v-divider></v-divider>
      <div class="login">
        <p>Schon ein Konto?</p>
        <router-link to="/login">Hier Anmelden</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      email: "",
      password: "",
      password_repeat: "",
      agreement: false,
      loading: false,
    };
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    password_repeat: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  computed: {
    mailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required &&
        errors.push("Bitte gib eine E-Mail Adresse an!");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push("Bitte gib ein Passwort an!");
      return errors;
    },
    passwordRepeatError() {
      const errors = [];
      !this.$v.password_repeat.sameAsPassword &&
        errors.push("Die Passwörter stimmen nicht überein!");
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password_repeat.required &&
        errors.push("Bitte gib ein Passwort an!");
      return errors;
    },
  },
  methods: {
    async signup() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.agreement) {
        this.loading = true;
        let res = await this.$store.dispatch("user/signup", {
          email: this.email,
          password: this.password,
        });
        if(res == 1){
          this.loading = false;
          return;
        }
        else if(res == 0){
          this.$swal({
            title: "Dein Konto wurde deaktiviert",
            text: "Bitte wende dich an den Support",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
          this.loading = false;
        }else if(res == -2){
          this.$swal({
            title: "Diese Email wird schon verwendet.",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
          this.loading = false;
        }else {
          this.$swal({
            title: "Es ist ein Fehler aufgetreten",
            text: "Bitte wende dich an unseren Support",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
          this.loading = false;
        }
      } else {
        if (!this.agreement) {
          this.$swal({
            title: "Bitte stimme den AGB und Datenschutzbestimmungen zu.",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
        if (!this.$v.email.required && this.$v.$dirty) {
          this.mailError;
        }
        if (!this.$v.password.required && this.$v.$dirty) {
          this.passwordError;
        }
        if (
          (!this.$v.password_repeat.required && this.$v.$dirty) ||
          (!this.$v.password_repeat.sameAsPassword && this.$v.$dirty)
        ) {
          this.passwordRepeatError;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.register {
  background: rgb(240, 240, 240);
  .container {
    max-width: 400px;
    width: 30%;
    max-height: 80%;
    background-color: #eff0f1;
    margin: 2rem auto 2rem auto;
    box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
    border-radius: 5px;

    form {
      margin: 0 auto;
      padding: 1rem;
      h2 {
        margin: 0;
      }
      label {
        padding: 0.5rem;
        display: block;
        text-align: left;
        .error--text {
          color: rgb(255, 0, 0) !important;
        }
        input {
          width: 100%;
          border-radius: 1rem;
          border: none;
          height: 2rem;
        }
      }
      button {
        padding: 0 0.5rem 0 0.5rem;
        width: 95%;
        background: rgb(255, 188, 143);
        background: linear-gradient(
          315deg,
          rgba(255, 188, 143, 1) 0%,
          rgba(255, 143, 158, 1) 100%
        );
        color: white;
        height: 2.5rem;
        border: none;
        border-radius: 5px;
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 1rem;
        text-transform: none;
        letter-spacing: 0px;
      }
    }
    .login {
      padding-bottom: 1rem;
      padding-top: 1rem;
      a {
        color: rgba(255, 143, 158, 1);
      }
    }

    .privacy {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      input {
        margin: 0 1rem;
      }
      p {
        font-size: 0.75rem;
        padding-left: 0rem;
        text-align: left;
      }
      a {
        color: rgba(255, 143, 158, 1);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .register {
    .container{
      width: 90%;
      max-width: 90%;
      height: calc(100% - 60px);
      margin: 1rem auto;
    }
  }
}
</style>