<template>
<div>
  <v-card class="wrapper container" @click="pushSettings">
      <div class="personal" >
        <h1>Persönliche Daten</h1>
        <p>Nickname: {{ user.nickname }}</p>
    </div>
  </v-card>
  </div>
</template>

<script>

export default {
  name: "PersonalContainer",
  components: {
  },
  data: function () {
    return {
      showModal: false,
    };
  },
  props: ["user"],
  methods: {
    pushSettings(){
      this.$store.commit("setSettingsState", 'profil');
      this.$router.push('/settings');
    },
  },
};
</script>
<style lang="less" scoped>
</style>