import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
}

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const auth_ = firebase.auth;

const nicknameCollection = db.collection('nicknameToUser');
const organizationCollection = db.collection('organization');
const userCollection = db.collection('user');
const campaignUserCollection = db.collection('campaign');

export {
  db,
  auth,
  auth_,
  storage,
  nicknameCollection,
  organizationCollection,
  userCollection,
  campaignUserCollection,
};