<template>
    <div>
      <v-card class="map container" @click="$router.push('/map')">
        <h1>Zur Kartenansicht</h1>
      </v-card>    
    </div>
    
</template>

<script>

export default ({
    name: 'OrgaContainer',
    props: ['selectedOrga'],
})
</script>
