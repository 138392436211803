<template>
  <div class="content">
    <v-container class="container2">
      <v-stepper v-model="step" class="stepper">
        <v-stepper-header class="header">
          <v-stepper-step class="circle" :complete="step > 1" step="1">
            <span class="text">Informationen</span>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            <span class="text">Plan auswählen</span>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 3" step="3">
            <span class="text">Bezahlung</span>
          </v-stepper-step>

          <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items class="stepperContent">
          <v-stepper-content step="1">
            <v-card>
              <v-card-text>
                Die erstellte Kampagne kann bis
                <span class="date"> {{ date }} </span> genutzt werden.
                <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.name.$error }"
                >
                  <v-text-field
                    v-model="name"
                    label="Name der Kampagne"
                    :error-messages="nameError"
                  ></v-text-field>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn @click="nameNext"> Weiter </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn text @click="cancel"> Abbrechen </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">

            <v-card v-for="item in products" :key="item.stipe_price" class="products" @click="selectedProduct = item">
              <v-card-title>
                {{item.plan_name}} | {{item.price}} EUR
                <v-btn icon :loading="loadProduct" v-if="selectedProduct === item"><v-icon>mdi-check</v-icon></v-btn>
              </v-card-title>
              <v-card-text>
                {{item.plakate}} Plakate - {{item.members}} Personen - App & Webseite | 3 Monate
              </v-card-text>
            </v-card>

            <v-card-actions>
              <v-row>
                <v-col>
                  <v-btn @click="step = 3" :disabled="!selectedProduct">Weiter</v-btn>
                </v-col>
                <v-col>
                  <v-btn text @click="cancel">Abbrechen</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card>
              <v-card-text>
                <h4>Ihre gebuchte Kampagne:</h4><br/>
                Gültig bis: <span class="date"> {{ date }} </span> <br/>
                Paket: <span class="plan"> {{ selectedProduct.plan_name }} </span><br/>
                Preis: <span class="price"> {{ selectedProduct.price}}</span><br/>
                <br/>
                Gutscheine können im nächsten Fenster eingelöst werden.               
              </v-card-text>
              <v-card-actions>
                <stripe-checkout
                  ref="checkoutRef"
                  :pk="publishableKey"
                  :session-id="sessionId"
                />
                <v-row>
                  <v-col>
                    <v-btn @click="submit" :loading="loading" :disabled="loading">Zur Bezahlung</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn @click="step = 2"> Zurück </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn text @click="cancel"> Abbrechen </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card> 
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout,
  },
  name: "CreateCampaign",
  data() {
    this.publishableKey =
      "pk_live_51IyghFA1i2rNfwN5H8YX13i1bTUXqIdGil4ciTSnSjI811Z4nJ1khu9DagZuDkmRUylXTvCKp6NLz69vwm1OunMH00RtgZLZHn";
    return {
      step: 1,
      selectedProduct: {
        stripe_price: '',
        plan_name: '',
        price: '',
        plakate: '',
        members: ''
      },
      loadProduct: false,
      name: "",
      loading: false,
      sessionId: null,
      leave: false,
      products: [
        /*{
          stripe_price: 'price_1JHyDOA1i2rNfwN51XLRjsxM',
          plan_name: 'Test_Produkt',
          price: '0,50',
          plakate: '1',
          members: '1'
        },*/
        {
          stripe_price: 'price_1JHwa9A1i2rNfwN5BNJBCI67',
          plan_name: 'Ortsverein Small',
          price: '14,99',
          plakate: '100',
          members: '4'
        },
        {
          stripe_price: 'price_1JHwajA1i2rNfwN53MfD3CzP',
          plan_name: 'Ortsverein Medium',
          price: '29,99',
          plakate: '250',
          members: '10'
        },        {
          stripe_price: 'price_1JHwaoA1i2rNfwN5OMdoIAvF',
          plan_name: 'Ortsverein Large',
          price: '49,99',
          plakate: '500',
          members: '20'
        },        {
          stripe_price: 'price_1JHwb2A1i2rNfwN51xWYL73G',
          plan_name: 'Kreisverband Small',
          price: '99,99',
          plakate: '1000',
          members: '40'
        },        {
          stripe_price: 'price_1JHwb6A1i2rNfwN5VfvFX1aJ',
          plan_name: 'Kreisverband Medium',
          price: '249,99',
          plakate: '2500',
          members: '100'
        },        {
          stripe_price: 'price_1JHwb9A1i2rNfwN5CT98dL8K',
          plan_name: 'Kreisverband Large',
          price: '499,99',
          plakate: '5000',
          members: '200'
        },        
      ]
    };
  },
  validations: {
    name: {
      required,
    },
  },
  watch: {
    sessionId: function () {
      if (this.sessionId) {
        this.loading = false;
        this.$refs.checkoutRef.redirectToCheckout();
      }
    },
  },
  beforeRouteLeave(to, from, next){
    if(this.leave){
      next();
      return;
    }
    this.$swal({
      title: "Möchtest du die Seite wirklich verlassen?",
      text: "Die Erstellung der Kampagne wird abgebrochen",
      icon: "warning",
      showCancelButton: true,
      customClass: {
          confirmButton: 'btn btn-confirm',
          cancelButton: 'btn btn-cancel'
        },
        buttonsStyling: false,
      confirmButtonText: "Verlassen",
      cancelButtonText: "Abbrechen",
    }).then((result) => {
      if(result.isConfirmed){
        next()
      }else{
        next(false)
      }
    })
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required &&
        errors.push("Bitte gib einen Namen der Kampagne an");
      return errors;
    },
    date() {
      let today = new Date();
      let newDate = this.calcDate(today, 90);
      let month = "" + (newDate.getMonth()+1);
      let day = "" + newDate.getDate();
      let year = "" + newDate.getFullYear();
      return [day, month, year].join(".");
    },
  },
  methods: {
    calcDate(date, days){
      var result = new Date(date.getTime());
      result.setDate(result.getDate() + days);
      return result;
    },
    nameNext() {
      this.$v.name.$touch();
      if (!this.$v.name.$invalid) {
        this.step = 2;
      }
    },
    cancel() {
      this.$swal({
        title: "Möchtest du die Erstellung der Kampagne wirklich abbrechen?",
        icon: "question",
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-confirm',
          cancelButton: 'btn btn-cancel'
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
      }).then((result) => {
        if (result.isConfirmed) {
          this.leave = true;
          this.$router
            .push("/")
            .catch(() => console.error("Error Redirect from Campaign Cancel"));
        }
      });
    },
    async submit() {
      this.loading = true;
      this.leave = true;
      await this.$store.dispatch(
        "user/createCheckoutSession", {
          callback: (id) => {
            this.sessionId = id;
          },
          campaignName: this.name,
          selectedProduct: this.selectedProduct.stripe_price, 
        }
      ).then((res) => {
          if(res == -1){
            this.$swal({
              title: "Bitte versuche es später noch einmal",
              text: "Es ist ein Fehler aufgetreten",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-confirm",
              },
              confirmButtonText: "OK",
              buttonsStyling: false,
            });
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container2 {
  width: clamp(400px, 90%, 1500px);
  height: 80%;
  background-color: #eff0f1;
  margin: 2rem auto 2rem auto;
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
  border-radius: 5px;
  .stepper {
    height: 100%;
    border-radius: 5px;
    overflow: scroll;
    .stepperContent {
      text-align: left;
      .error--text {
        color: rgb(255, 0, 0) !important;
      }
      .date,.price,.plan {
        font-weight: 700;
      }
    }
    .products{
      margin: 0.5rem;
      padding: 0.5rem;
      border-radius: 5px;
      text-align: left;
      &:hover {
        box-shadow: 2.5px 2.5px 10px 0px rgba(0, 0, 0, 0.5);
        transition: box-shadow 0.15s ease-in-out;
      }
    }
    .header {
      background: rgb(255, 188, 143);
      background: linear-gradient(
        315deg,
        rgba(255, 188, 143, 1) 0%,
        rgba(255, 143, 158, 1) 100%;
      );
      .text {
        padding-left: 5px;
      }
    }
  }
}
</style>