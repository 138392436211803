<template>
  <div id="footer">
    <div class="section left"></div>
    <div class="section main">
      <span class="copyright">© 2021 Stactix</span>
    </div>
    <div class="section right">
      <a href="https://stactix.com/impressum">Impressum</a>
      <a href="https://stactix.com/datenschutz">Datenschutzerklärung</a>
      <a href="https://stactix.com/agb">AGB</a>
      <a href="https://stactix.com">Über Uns</a>
    </div>
  </div>
</template>

<script>

export default {
  name: "FooterComp",
  data() {
    return {
    };
  },
};
</script>

<style lang="less" scoped>
#footer {
  padding: 0 2rem 0 2rem;
  height: 40px;
  z-index: 10;
  position: absolute;
  bottom:0;
  width: 100vw;
  border-top: 3px rgb(255, 143, 158) solid;
  background-color: transparent;
  color: rgb(255, 143, 158);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: "Helvetica";
  .section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .main {
    justify-content: center;
  }
  .right {
    justify-content: flex-end;
  }
  a {
    padding: 0 0.75rem;
    text-decoration: none;
    font-size: 1rem;
    color: rgb(255, 143, 158);

    &:hover {
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }
}

@media screen and (max-width: 768px) {
  #footer {
    position: relative;
    grid-template-columns: 1fr;
    grid-template-rows:  1fr 1fr 2fr;
    width: 100vw;
    height: auto;
    padding-bottom: 1rem;
    background: rgb(240, 240, 240);
    .right{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
</style>
