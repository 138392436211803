import * as fb from '../../utils/firebase'
//import router from '../../router';

export default{
    namespaced: true,
    state: {
        userCampaigns:{},
        selectedCampaign: {campaign: false, orga: false},
        campaignInfo: {},
        campaignMember:[],
        plakate:[],
        userCampaignListener: null,
        plakateListenerUnsub:null,
        campaignMemberListenerUnsub: null,
        selCampaignListenerUnsub:null,
        campaignInfoListenerUnsub: null,
    },
    mutations:{
        setUserCampaigns(state, val) {
            state.userCampaigns = val;
        },
        setSelectedCampaign(state, val){
            state.selectedCampaign = val;
        },
        setCampaignInfo(state, val){
            state.campaignInfo = val;
        },
        setCampaignMember(state, val){
            state.campaignMember = val;
        },
        setPlakate(state, val){
            state.plakate = val;
        },
        setUserCampaignsListener(state, val){
            state.userCampaignListener = val;
        },
        setPlakatListener(state, val){
            state.plakateListenerUnsub = val;
        },
        setCampaignMemberListener(state, val){
            state.campaignMemberListenerUnsub = val;
        },
        setSelCampaignListener(state, val){
            state.selCampaignListenerUnsub = val;
        },
        setCampaignInfoListener(state, val){
            state.campaignInfoListenerUnsub = val;
        },
        resetUserCampaignsListener(state){
            state.userCampaignListener;
        },
        resetPlakatListener(state){
            state.plakateListenerUnsub();
        },
        resetCampaignMemberListener(state){
            state.campaignMemberListenerUnsub();
        },
        resetSelCampaignListener(state){
            state.selCampaignListenerUnsub();
        },
        resetCampaignInfoListener(state){
            state.campaignInfoListenerUnsub();
        },
    },
    getters: {
        userCampaigns: state => {
            return state.userCampaigns;
        },
        selectedCampaign: state => {
            return state.selectedCampaign;
        },
        campaignMember: state => {
            return state.campaignMember;
        },
        plakate: state => {
            return state.plakate;
        },
        campaignInfo: state => {
            return state.campaignInfo;
        },
    },
    actions: {
        getUserCampaigns({commit, rootGetters}){
            let selectedOrga = rootGetters['orga/selectedOrga'];
            const unsubscribe = fb.userCollection.doc(fb.auth.currentUser.uid).collection("organization").doc(selectedOrga.orga.id)
            .collection('campaign').onSnapshot(snapshot => {
                let campaignArray = [];
                snapshot.forEach(doc => {
                    let campaign = doc.data();
                    campaign.id = doc.id;
                    campaignArray.push(campaign);
                })    
                commit('setUserCampaigns', campaignArray);
            });
            commit('setUserCampaignsListener', unsubscribe);
        },
        initGetSelectedCampaign({rootGetters, commit, dispatch}, campaign){
            const selectedOrga = rootGetters['orga/selectedOrga'];
            fb.organizationCollection.doc(selectedOrga.orga.id).collection('campaign')
            .doc(campaign.id).get().then((snapshot) => {
                let campaignData = snapshot.data();
                campaignData.id = snapshot.id;
                let selectedCampaignTuple = {
                    orga: selectedOrga.orga,
                    campaign: campaignData,
                }
                commit('setSelectedCampaign', selectedCampaignTuple);
                dispatch('getSelectedCampaign', campaignData);
                dispatch('getCampaignInfo');
                dispatch('getCampaignMembers', campaignData);
                dispatch('getPlakate', campaignData);
            }); 
        },
        getSelectedCampaign({rootGetters,commit}, campaign){
            const selectedOrga = rootGetters['orga/selectedOrga'];
            const orga = selectedOrga.orga;
            const unsubscribe = fb.organizationCollection.doc(orga.id).collection('campaign')
            .doc(campaign.id).onSnapshot((snapshot) => {
                let campaignData = snapshot.data();
                campaignData.id = snapshot.id;
                let selectedCampaignTuple = {
                    orga: orga,
                    campaign: campaignData,
                }
                commit('setSelectedCampaign', selectedCampaignTuple);
            });
            commit('setSelCampaignListener', unsubscribe);  
        },
        getCampaignMembers({rootGetters, commit}, campaign){
            const selectedOrga = rootGetters['orga/selectedOrga'];
            const orga = selectedOrga.orga;
            const unsubscribe = fb.organizationCollection.doc(orga.id).collection('campaign')
            .doc(campaign.id).collection("user").onSnapshot(snapshot => {
                let campaignMemberArray = [];
                snapshot.forEach(doc => {
                    let member = doc.data();
                    member.id = doc.id;
                    campaignMemberArray.push(member);
                });
                commit('setCampaignMember', campaignMemberArray);
            });
            commit('setCampaignMemberListener', unsubscribe);
        },
        getCampaignInfo({rootGetters, getters, commit}){
            const selectedOrga = rootGetters['orga/selectedOrga'];
            const selectedCampaign = getters['selectedCampaign'];
            const unsubscribe = fb.organizationCollection.doc(selectedOrga.orga.id).collection('campaign_info')
            .doc(selectedCampaign.campaign.id).onSnapshot((snapshot) => {
                let campaignInfo = snapshot.data();
                campaignInfo.id = snapshot.id;
                commit('setCampaignInfo', campaignInfo);
            });
            commit('setCampaignInfoListener', unsubscribe);
        },
        getPlakate({commit, rootGetters}, campaign){
            const selectedOrga = rootGetters['orga/selectedOrga'];
            const unsubscribe = fb.organizationCollection.doc(selectedOrga.orga.id).collection("campaign")
            .doc(campaign.id).collection("plakat").onSnapshot(snapshot => {
                let plaktArray = [];
                snapshot.forEach(doc => {
                    let plakat = doc.data();
                    plakat.id = doc.id;
                    plaktArray.push(plakat);
                })
                commit('setPlakate', plaktArray);
            });
            commit('setPlakatListener', unsubscribe);
        },
        async setPlakate({rootGetters, getters}, plakate){
            const selectedOrga = rootGetters['orga/selectedOrga'];
            const selectedCampaign = getters['selectedCampaign'];
            try{
                return await fb.db.runTransaction(async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(selectedOrga.orga.id))
                    .then(() => {
                        for(let plakat of plakate){
                            transaction.set(
                                fb.organizationCollection.doc(selectedOrga.orga.id)
                                .collection("campaign").doc(selectedCampaign.campaign.id)
                                .collection("plakat").doc(), 
                                plakat
                            );
                        }
                    });
                }).then(() => {
                    return 1;
                });
            }catch(error){
                console.log(error)
                if(error.code === 'permission-denied'){
                    return 0;
                }
                else{
                    return -1;
                }
            }
        },
        async updatePlakat({getters, rootGetters}, plakat){
            const selectedCampaign = getters['selectedCampaign'];
            const selectedOrga = rootGetters['orga/selectedOrga'];
            try { 
                return await fb.db.runTransaction(async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(selectedOrga.orga.id))
                    .then(() => {
                        transaction.update(
                            fb.organizationCollection.doc(selectedOrga.orga.id)
                            .collection('campaign').doc(selectedCampaign.campaign.id)
                            .collection('plakat').doc(plakat.id), plakat
                        );
                    });
                }).then(()=> {
                    return 1;
                });
            }catch(error){
                console.log(error)
                return -1;
            }
        },
        async deletePlakat({getters, rootGetters}, plakat){
            const selectedCampaign = getters['selectedCampaign'];
            const selectedOrga = rootGetters['orga/selectedOrga'];
            try { 
                return await fb.db.runTransaction(async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(selectedOrga.orga.id))
                    .then(() => {
                        transaction.delete(
                            fb.organizationCollection.doc(selectedOrga.orga.id)
                            .collection('campaign').doc(selectedCampaign.campaign.id)
                            .collection('plakat').doc(plakat.id)
                        );
                    });
                }).then(()=> {
                    return 1;
                });
            }catch(error){
                return -1;
            }
        },
        async leaveCampaign({getters, rootGetters, commit}){
            let campaignTuple = getters['selectedCampaign'];
            let user = rootGetters['user/user'];
            try{
                return await fb.db.runTransaction(async (transaction) => {
                    await transaction.get(fb.userCollection.doc(user.id).collection('organization')
                    .doc(campaignTuple.orga.id).collection('campaign').doc(campaignTuple.campaign.id))
                    .then(() => {
                        transaction.delete(fb.userCollection.doc(user.id).collection('organization')
                        .doc(campaignTuple.orga.id).collection('campaign').doc(campaignTuple.campaign.id));
                    });
                }).then(() => {
                    commit('setSelectedCampaign', {campaign: false, orga: false});
                    return 1;
                });
            }catch(e){
                console.log(e);
                return -1;
            } 
        },
        async inviteUser({getters}, nickname){
            let campaignTuple = getters['selectedCampaign'];
            try{
                return await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.nicknameCollection.doc(nickname))
                    .then((doc) => {
                        let userInvID = doc.data().uid;
                        transaction.set(fb.organizationCollection.doc(campaignTuple.orga.id).collection('campaign')
                        .doc(campaignTuple.campaign.id).collection('user').doc(userInvID), {
                            nickname: nickname,
                            role: 'member',
                        });
                    });
                }).then(() => {
                    return 1;
                });
            } catch(e){
                console.log(e);
                return -1;
            } 
        },
        async updateMember({getters}, member){
            let campaignTuple = getters['selectedCampaign'];
            try{
                return await fb.db.runTransaction(async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(campaignTuple.orga.id))
                    .then(() => {
                        transaction.update(fb.organizationCollection.doc(campaignTuple.orga.id)
                        .collection('campaign').doc(campaignTuple.campaign.id).collection('user').doc(member.id),
                            { role: member.role}
                        );
                    });
                }).then(() => {
                    return 1;
                });
            }catch(e){
                console.log(e);
                return -1;
            } 
        },
        async deleteUser({getters}, member){
            let campaignTuple = getters['selectedCampaign'];
            try{
                return await fb.db.runTransaction(async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(campaignTuple.orga.id))
                    .then(() => {
                        transaction.delete(fb.organizationCollection.doc(campaignTuple.orga.id)
                        .collection('campaign').doc(campaignTuple.campaign.id).collection('user').doc(member.id));
                    });
                }).then(() => {
                    return 1;
                });
            }catch(e){
                console.log(e);
                return -1;
            } 
        },
        async deleteCampaign({getters}){
            let campaignTuple = getters['selectedCampaign'];
            try{
                return await fb.db.runTransaction(async (transaction) => {
                    await transaction.get(fb.organizationCollection.doc(campaignTuple.orga.id))
                    .then(() => {
                        transaction.delete(fb.organizationCollection.doc(campaignTuple.orga.id)
                        .collection('campaign').doc(campaignTuple.campaign.id));
                    });
                }).then(() => {
                    return 1;
                });
            }catch(e){
                console.log(e);
                return -1;
            } 
        },
    },
};