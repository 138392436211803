<template>
  <div id="app">
    <navbar/>
    <router-view/>
    <footer-comp v-if="currentRouteName !== 'Map'"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue';
import FooterComp from './components/FooterComp.vue';

export default {
  name: 'App',
  components: {
    FooterComp,
    Navbar, 
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
  created(){
        document.title = "Stactix"
    }
}
</script>
<style lang='less'>

body{
  margin: 0;
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.content{
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    position: absolute;
    width: 100vw;
    top: 60px;
}

@media screen and (max-width: 768px){
.content{
    min-height: auto;
    height: auto;
    display: flex;
    align-items: center;
    position: relative;
    width: 100vw;
    padding-top: 60px;
    top: 0;
}
}

.swal2-popup {
   font-family: Helvetica, Arial, sans-serif !important;
}

.btn{
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  margin: 0 0.25rem;
}
.btn-confirm{
  color: white !important;
  background-color: rgba(255, 143, 158, 1);
}
.btn-cancel{
  background-color: white ;
  color: rgba(255, 143, 158, 1) !important;
  border: 1px rgba(255, 143, 158, 1) solid;
}

.toastbtn{
  font-size: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  margin: 0.25rem;
}
.toastbtn-confirm{
  color: white !important;
  background-color: rgba(255, 143, 158, 1);
}
.toastbtn-cancel{
  background-color: white ;
  color: rgba(255, 143, 158, 1) !important;
  border: 1px rgba(255, 143, 158, 1) solid;
}

span.v-stepper__step__step.primary{
        border: 1px white solid;
    }
</style>

