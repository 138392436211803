import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../views/Register'
import Login from '../views/Login'
import Dashboard from '../views/Dashboard'
import OrgaSelection from '../views/OrgaSelection'
import CampaignSelection from '../views/CampaignSelection'
import Onboarding from '../views/Onboarding'
import CreateOrga from '../views/CreateOrga'
import CreateCampaign from '../views/CreateCampaign'
import PaymentResult from '../views/PaymentResult'
import PasswordReset from '../views/PasswordReset'
import EmailVerification from '../views/EmailVerification'
import { auth } from '../utils/firebase'
import store from './../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: "Home",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
      requiresOrga: true,
      requiresCampaign: true,
    }
  },
  {
    path: '/orgaSelect',
    name: "OrgaSelect",
    component: OrgaSelection,
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
      requiresEmailAuth: true,
    }
  },
  {
    path: '/campaignSelect',
    name: "CampaignSelect",
    component: CampaignSelection,
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
      requiresOrga: true,
      requiresEmailAuth: true,
    }
  },
  {
    path: '/register',
    name: "Register",
    component: Register
  },
  {
    path: '/login',
    name: "Login",
    component: Login
  },
  {
    path: '/passwordReset',
    name: "PasswordReset",
    component: PasswordReset
  },
  {
    path: '/emailVerification',
    name: "EmailVerification",
    component: EmailVerification,
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
    }
  },
  {
    path: '/map',
    name: "Map",
    component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
      requiresOrga: true,
      requiresCampaign: true,
      requiresEmailAuth: true,
    }
  },
  {
    path: '/settings',
    name: "Settings",
    component: () => import(/* webpackChunkName: "map" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
      requiresOrga: true,
      //requiresCampaign: true,
      requiresEmailAuth: true,
    },
  },
  {
    path: '/onboarding',
    name: "Onboarding",
    component: Onboarding,
    meta: {
      requiresAuth: true,
      requiresOnboard: false,
    }
  },
  {
    path: '/createOrga',
    name: "CreateOrga",
    component: CreateOrga,
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
      requiresEmailAuth: true,
    }
  },
  {
    path: '/createCampaign',
    name: "CreateCampaign",
    component: CreateCampaign,
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
      requiresOrga: true,
      requiresEmailAuth: true,
    },
  },
  {
    path: '/paymentResult',
    name: "PaymentResult",
    component: PaymentResult,
    meta: {
      requiresAuth: true,
      requiresOnboard: true,
      requiresOrga: true,
      requiresEmailAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresOnboard = to.matched.some(x => x.meta.requiresOnboard)
  const requiresOrga = to.matched.some(x => x.meta.requiresOrga)
  const requiresCampaign = to.matched.some(x => x.meta.requiresCampaign)
  const requiresEmailAuth = to.matched.some(x => x.meta.requiresEmailAuth)
  let user = store.getters['user/user'];
  let firebaseUser = store.getters['firebaseUser'];
  let selOrgaTuple = store.getters['orga/selectedOrga'];
  let selCampaignTuple = store.getters['orga/campaign/selectedCampaign'];
  
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  }else if(requiresOnboard && !user.isOnboarded){
    next('/onboarding')
  }else if(requiresEmailAuth && !firebaseUser.emailVerified){
    next('/emailVerification')
  }else if(requiresOrga && selOrgaTuple.user == false || requiresOrga && selOrgaTuple.user.id != user.id || requiresOrga && selOrgaTuple.orga == false){
    next('/orgaSelect')
  }
  else if(requiresCampaign && selCampaignTuple.orga == false || requiresCampaign && selCampaignTuple.orga.id != selOrgaTuple.orga.id){
    next('/campaignSelect')
  }
  else {
    next()
  }
})

export default router
