<template>
      <v-card class="campaign container" @click="pushSettings">
        <h1>Aktuelle Kampagne:</h1>
        <h4>{{selectedCampaign.name}}</h4>
        <p>Kampagnenmanager: {{selectedCampaign.manager}}</p>
      </v-card>
</template>

<script>

export default ({
  name: 'CampaignContainer',
  props: ['selectedCampaign'],
  methods:{
    pushSettings(){
      this.$store.commit("setSettingsState", 'campaignSettings');
      this.$router.push('/settings');
    },
  },
})
</script>