import * as fb from '../../utils/firebase'
import router from '../../router/index'

// SignInMethod Enum
const SignInMethod = {
    'Email': 1,
    'Google': 2,
    'Apple': 3,
}
Object.freeze(SignInMethod);
export {SignInMethod};

export default{
    namespaced: true,
    state:{
        user: {},
        userSignInMethod: false,
        userListenerUnsub:null,
        providerLoading: false,
        appleLoading: false,
        googleLoading: false,
        paymentResult: null,
    },
    mutations:{
        setUserProfile(state, val) {
            state.user = val;
        },
        setUserSignInMethod(state, val){
            state.userSignInMethod = val;
        },
        setUserListener(state, val){
            state.userListenerUnsub = val;
        },
        resetUserListener(state){
            state.userListenerUnsub();
        },
        setProviderLoading(state, val){
            state.providerLoading = val;
        },
        setGoogleLoading(state, val){
            state.googleLoading = val;
        },
        setAppleLoading(state, val){
            state.appleLoading = val;
        },
        setPaymentResult(state, val){
            state.paymentResult = val;
        }
    },
    getters: {
        user: state => {
            return state.user;
        },
        userSignInMethod: state => {
            return state.userSignInMethod;
        },
        providerLoading: state => {
            return state.providerLoading;
        },
        appleLoading: state => {
            return state.appleLoading;
        },
        googleLoading: state => {
            return state.googleLoading;
        },
        paymentResult: state => {
            return state.paymentResult;
        }
    },
    actions:{
        async createCheckoutSession({rootGetters}, params){
            let user = rootGetters['user/user'];
            let selectedOrga = rootGetters['orga/selectedOrga'];
            try{
                const ref = fb.db.collection("user").doc(user.id).collection('checkout_sessions').doc();
                const sessionDocId = ref.id;
                await (ref.set(
                    {
                        mode: "payment",
                        price: params.selectedProduct,
                        success_url: "https://app.stactix.com/paymentResult/?paymentRes=success",
                        cancel_url: "https://app.stactix.com/paymentResult/?paymentRes=cancel",
                        //success_url: "http://localhost:8080//paymentResult/?paymentRes=success",
                        //cancel_url: "http://localhost:8080/paymentResult/?paymentRes=cancel",
                        manager: user.nickname,
                        metadata: {sessionDocId: sessionDocId},
                        orgaId: selectedOrga.orga.id,
                        campaignName: params.campaignName,
                        payment_method_types: ['card'],
                        locale: 'de',
                        line_items: [{
                            price: params.selectedProduct,
                            quantity: 1,
                            tax_rates: ['txr_1JKUwoA1i2rNfwN5uh5wrxtJ'],
                        }],
                        allow_promotion_codes: true,
                    }
                ));
                ref.onSnapshot(async (snap) => {
                    const {error, sessionId = null} = snap.data();
                    if(error){
                        throw 'error_snapshot_checkout'; 
                    }
                    if(sessionId){
                        params.callback(sessionId);
                    }
                });
            } catch(error){
                return -1;
            }
        },
        async login({ dispatch, commit }, form) {
            try{
                const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password);
                const fbUser = fb.auth.currentUser;
                commit('setFirebaseUser', fbUser, {root:true});
                dispatch('userStream', user);
                return 1;
            }catch(error){
                if(error.code == 'user-disabled'){
                    return 0;
                }
                else{
                    return -1;
                }
            }
        },
        async loginGoogle({commit}){
            try{
                var googleProvider = new fb.auth_.GoogleAuthProvider();
                await fb.auth.signInWithRedirect(googleProvider);
                return 1;
            }catch(error){
                commit('setGoogleLoading', false);
                if(error.code == 'auth/popup-closed-by-user'){
                    return 0;
                }else{
                    return -1;
                }
            }
        },
        async providerRedirect({dispatch, commit}, redirect_user){
            await fb.userCollection.doc(redirect_user.uid).get();
            const fbUser = fb.auth.currentUser;
            commit('setFirebaseUser', fbUser, {root:true});
            dispatch('userStream', redirect_user);
        },
        async loginApple({commit}){
            try{
                var appleProvider = new fb.auth_.OAuthProvider('apple.com');
                await fb.auth.signInWithRedirect(appleProvider);
                return 1;
            }catch(error){
                commit('setAppleLoading', false);
                if(error.code == 'auth/popup-closed-by-user'){
                    return 0;
                }else{
                    return -1;
                }
            }
        },
        async userStream({commit, dispatch}, user){
            try{
                const unsubscribe = fb.userCollection.doc(user.uid).onSnapshot((snapshot) => {
                    let userDoc = snapshot.data();
                    if(typeof(userDoc) !== 'undefined'){
                        userDoc.id = snapshot.id;
                        commit('setUserProfile', userDoc);
                        dispatch('getSignInMethod');
                    }
                });
                dispatch('orga/getUserOrgas', null, {root: true});
                commit('setUserListener', unsubscribe);
                commit('setGoogleLoading', false);
                commit('setAppleLoading', false);
            }catch(error){
                console.log(error);
            }
        },
        async signup({ dispatch, commit }, form) {
            try{
                const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password);
                await fb.auth.currentUser.sendEmailVerification();
                const fbUser = fb.auth.currentUser;
                console.log(fbUser);
                commit('setFirebaseUser', fbUser, {root:true});
                dispatch('userStream', user)
                return 1;
            }catch(e){
                console.error(e.code);
                if(e.code == 'auth/user-disabled'){
                    return 0;
                }else if(e.code == 'auth/email-already-in-use'){
                    return -2;
                }else{
                    return -1;
                }
            }
        },
        async onboard({getters, commit}, userUpdate){
            let user = getters['user'];
            try{
                return await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.userCollection.doc(user.id))
                    .then(() => {
                        transaction.set(fb.userCollection.doc(user.id),{
                            isOnboarded: true,
                            nickname: userUpdate.nickname,
                        });
                    });
                }).then(() => {
                    user.nickname = userUpdate.nickname;
                    user.isOnboarded = true;
                    commit('setUserProfile', user);
                    router.push('/emailVerification');
                });
            } catch(error){
                console.log(error);
                if(error.code === 'permission-denied'){
                    return 0;
                }
                else{
                    return -1;
                }
            } 
        },
        /*async updateUserData({getters}, userUpdate){
            let user = getters['user'];
            try{
                await fb.db.runTransaction( async (transaction) => {
                    await transaction.get(fb.userCollection.doc(user.id))
                    .then(() => {
                        transaction.update(fb.userCollection.doc(user.id),{
                            firstName: userUpdate.firstName,
                            lastName: userUpdate.lastName,
                        });
                    });
                }).then(() => {
                    return 0;
                });
            } catch(error){
                console.log(error.code);
                if(error.code === 'permission-denied'){
                    return -1;
                }
                else{
                    return -1;
                }
            } 
        },*/
        async deleteUser({dispatch, commit, getters}, password){
            const res = await dispatch('reauthenticate', password);
            if(res == 1){
                const user = getters['user'];
                try{
                    await fb.db.runTransaction( async (transaction) => {
                        await transaction.get(fb.userCollection.doc(user.id))
                        .then(async () => {
                            transaction.delete(fb.userCollection.doc(user.id));   
                        });
                    }).then(async () => {
                        await fb.auth.currentUser.delete();
                        commit('user', {})
                        commit('orga/setSelectedOrga', {user: false, orga: false}, {root: true});
                        commit('orga/campaign/setSelectedCampaign', {campaign: false, orga: false}, {root: true});
                        return 0;
                    });
                }catch(e){
                    console.log(e);
                    return -1;
                }
            }else{
                return -1;
            }
            
        },
        async changePassword({dispatch}, pwObject){
            try{
                await dispatch('reauthenticate', pwObject.oldPassword).then((res) => {
                    if(res == -1){
                        throw 'unknown reauth-error'; 
                    }
                    if(res == 0){
                        throw 'wrong-pw'
                    }
                });
                await fb.auth.currentUser.updatePassword(pwObject.newPassword);
                return 1;
            }catch(e){
                if(e == 'wrong-pw') return 0;
                else return -1;
            }
            
        },
        getSignInMethod({commit}){
            const user = fb.auth.currentUser;
            switch(user.providerData[0].providerId){
                case 'password':
                    commit('setUserSignInMethod', SignInMethod.Email);
                    return SignInMethod.Email;
                case 'google.com':
                    commit('setUserSignInMethod', SignInMethod.Google);
                    return SignInMethod.Google;
                case 'apple.com':
                    commit('setUserSignInMethod', SignInMethod.Apple);
                    return SignInMethod.Apple;
                default:
                    console.error('unknown provider');
                    return -1;
            }
        },
        async resetPassword(x, email){
            try{
                await fb.auth.sendPasswordResetEmail(email);
                return 1
            }catch(e){
                if(e.code == "auth/user-not-found"){
                    return 0;
                }else{
                    return -1;
                }                
            } 
        },
        async sendEmailVerification(){
            try{
                await fb.auth.currentUser.sendEmailVerification();
                return 1;
            }catch(e){
                console.log(e);
                if(e.code == "auth/too-many-requests"){
                    return 0;
                }else{
                    return -1;
                }
            }
        },
        async reauthenticate({getters}, password){
            const userSignInMethod = getters['userSignInMethod'];
            try{
                if(userSignInMethod == SignInMethod.Email){
                    const credential = fb.auth_.EmailAuthProvider.credential(
                        fb.auth.currentUser.email, 
                        password
                    );
                    await fb.auth.currentUser.reauthenticateWithCredential(credential);
                }else if(userSignInMethod == SignInMethod.Google){
                    var googleProvider = new fb.auth_.GoogleAuthProvider();
                    await fb.auth.currentUser.reauthenticateWithPopup(googleProvider);
                }else if(userSignInMethod == SignInMethod.Apple){
                    var appleProvider = new fb.auth_.OAuthProvider('apple.com');
                    await fb.auth.currentUser.reauthenticateWithPopup(appleProvider);
                }
                return 1;
            }catch(error){
                if(error.code == 'auth/wrong-password'){
                    return 0;
                }else{
                    return -1;
                }
            } 
        },
    },
};