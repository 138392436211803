<template>
  <div class="register content">
    <div class="container">
      <form class="register-form" @submit.prevent="onboard">
        <h2>Registrieren</h2>
        <!--<div class="form-group" :class="{ 'form-group--error': $v.vorname.$error }">
                    <label for="vorname">
                        <v-text-field v-model="vorname" name="vorname" type="text" label="Vorname" :error-messages="vornameError"/>
                    </label>
                </div>
                <div class="form-group" :class="{ 'form-group--error': $v.nachname.$error }">
                    <label for="nachname">
                        <v-text-field v-model="nachname" name="nachname" type="text" label="Nachname" :error-messages="nachnameError"/>
                    </label>
                </div> -->
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.nickname.$error }"
        >
          <label for="nickname">
            <v-text-field
              v-model="nickname"
              name="nickname"
              type="text"
              label="Nutzername"
              :error-messages="nicknameError"
            />
          </label>
        </div>
        <v-btn name="send" type="submit" :loading="loading" :disabled="loading"
          >Weiter</v-btn
        >
      </form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      vorname: "",
      nachname: "",
      nickname: "",
      nickValid: true,
      reqFailed: false,
      loading: false,
    };
  },
  validations: {
    /*vorname:{
            required,
        },
        nachname:{
            required,
        },*/
    nickname: {
      required,
    },
  },
  computed: {
    /*vornameError(){
            const errors = []
            if (!this.$v.vorname.$dirty)
                return errors
            !this.$v.vorname.required && errors.push('Bitte gib einen Vornamen an')
            return errors
        },
        nachnameError(){
            const errors = []
            if (!this.$v.nachname.$dirty)
                return errors
            !this.$v.nachname.required && errors.push('Bitte gib einen Nachnamen an')
            return errors
        },*/
    nicknameError() {
      const errors = [];
      if (!this.nickValid) {
        errors.push("Dieser Nutzername ist leider schon vergeben");
        return errors;
      }
      if (!this.$v.nickname.$dirty) return errors;
      !this.$v.nickname.required && errors.push("Bitte gib einen Nutzernamen an");
      return errors;
    },
  },
  methods: {
    async onboard() {
      let userUpdate = {
        firstName: this.vorname,
        lastName: this.nachname,
        nickname: this.nickname,
      };
      this.$v.$touch();
      this.error = "";
      if (!this.$v.$invalid) {
        this.loading = true;
        this.$store.dispatch("user/onboard", userUpdate).then((res) => {
          if (res === 0) {
            this.nickValid = false;
            this.loading = false;
          }
          if (res === -1) {
            this.reqFailed = true;
            this.loading = false;
            this.$swal({
              title: "Fehler",
              icon: "error",
              text: "Es ist etwas schief gelaufen..",
              customClass: {
                confirmButton: "btn btn-confirm",
              },
              confirmButtonText: "OK",
              buttonsStyling: false,
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.register {
  background: rgb(240, 240, 240);
  .container {
    max-width: 400px;
    width: 30%;
    max-height: 80%;
    background-color: #eff0f1;
    margin: 2rem auto 2rem auto;
    box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
    border-radius: 5px;

    form {
      margin: 0 auto;
      padding: 1rem;
      h2 {
        margin: 0;
      }
      .error {
        font-size: 0.8rem;
        color: red;
      }
      label {
        padding: 0.5rem;
        display: block;
        text-align: left;
        span {
          font-size: 1rem;
          font-weight: bold;
          line-height: 2rem;
        }
        .error--text {
          color: rgb(255, 0, 0) !important;
        }
        input {
          width: 100%;
          border-radius: 1rem;
          border: none;
          height: 2rem;
        }
      }
      button {
        padding: 0 0.5rem 0 0.5rem;
        width: 95%;
        background: rgb(255, 188, 143);
        background: linear-gradient(
          315deg,
          rgba(255, 188, 143, 1) 0%,
          rgba(255, 143, 158, 1) 100%
        );
        color: white;
        height: 2.5rem;
        border: none;
        border-radius: 5px;
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 1rem;
        text-transform: none;
        letter-spacing: 0px;
      }
      p {
        color: red;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .register {
    .container{
      width: 90%;
      max-width: 90%;
      height: calc(100% - 60px);
      margin: 1rem auto;
    }
  }
}
</style>