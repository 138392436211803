<template>
  <div class="verify content">
    <div class="container">
      <v-form class="verify-form" @submit.prevent="verify" lazy-validation>
        <h2>Email Verifikation</h2>
        <br/>
        <p>Bitte bestätige deinen Account mit Hilfe der E-Mail die dir zugesandt wurde. <br/> <br/> Falls du diese Email nicht bekommen oder gelöscht hast, lasse dir diese erneut zusenden</p>
        <v-btn
        class="submit"
          color="primary"
          dark
          type="submit"
          :loading="loading"
          :disabled="loading"
        >
          Erneut senden
        </v-btn>
      </v-form>
    </div>
  </div>
</template>
<script>

export default {
  name: "EmailVerification",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async verify(){
        let res = await this.$store.dispatch("user/sendEmailVerification");
        if(res == -1){
            this.$swal({
                title: "Es ist ein Fehler aufgetreten",
                text: "Bitte wende dich an unseren Support",
                icon: "error",
                iconColor: "rgba(255, 143, 158)",
                customClass: {
                confirmButton: "btn btn-confirm",
                },
                confirmButtonText: "OK",
                buttonsStyling: false,
            });
        }else if(res == 0){
            this.$swal({
                title: "Es wurden zu viele Anfragen geschickt",
                text: "Bitte wende dich an unseren Support",
                icon: "error",
                iconColor: "rgba(255, 143, 158)",
                customClass: {
                confirmButton: "btn btn-confirm",
                },
                confirmButtonText: "OK",
                buttonsStyling: false,
            });
        }
        else{
            this.$swal({
                title: "Wir haben dir die E-Mail erneut zugesandt",
                icon: "success",
                customClass: {
                confirmButton: "btn btn-confirm",
                },
                buttonsStyling: false,
            });
        }
    }
  },
};
</script>
<style lang="less" scoped>

.verify {
  background: rgb(240, 240, 240);
  .container {
    max-width: 400px;
    width: 30%;
    background-color: #eff0f1;
    margin: 2rem auto 2rem auto;
    max-height: 80%;
    box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
    form {
      margin: 0 auto;
      padding: 1rem;
      h2 {
        margin: 0;
      }
      label {
        .error--text {
          color: rgb(255, 0, 0) !important;
        }
        padding: 0.5rem;
        display: block;
        text-align: left;
        span {
          font-size: 1rem;
          font-weight: bold;
          line-height: 2rem;
        }
        input {
          width: 100%;
          border-radius: 1rem;
          border: none;
          height: 2rem;
        }
      }
      .submit {
        margin: 0.5rem;
        padding: 0 0.5rem 0 0.5rem;
        width: 95%;
        background: rgb(255, 188, 143);
        background: linear-gradient(
          315deg,
          rgba(255, 188, 143, 1) 0%,
          rgba(255, 143, 158, 1) 100%
        );
        color: white;
        height: 2.5rem;
        border: none;
        border-radius: 5px;
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 1rem;
        text-transform: none;
        letter-spacing: 1px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .verify {
    .container{
      width: 90%;
      max-width: 90%;
      height: calc(100% - 60px);
      margin: 1rem auto;
    }
  }
}
</style>