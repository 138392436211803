<template>
    <div >
      <v-card class="orgas container" @click="pushSettings">
        <h1>Aktuelle Organisation:</h1>
        <h2>{{selectedOrga.name}}</h2>
        <p>{{getOrgaCreator}}</p>
        <p>{{selectedOrga.location}}</p>
        <p>{{selectedOrga.party}}</p>
      </v-card>
    </div>
</template>

<script>

export default ({
    name: 'OrgaContainer',
    props: ['selectedOrga'],
    computed: {
      getOrgaMember() {
        return this.$store.getters["orga/orgaMember"];
      },
      getOrgaCreator(){
        let creatorName = '';
         this.getOrgaMember.forEach(user => {
          if(user.id === this.selectedOrga.creator){
            creatorName = user.firstName + ' ' + user.lastName;
          }
        });
        return creatorName;
      },
    },
    methods:{
      pushSettings(){
        this.$store.commit("setSettingsState", 'orgaSettings');
        this.$router.push('/settings');
      },
    },
})
</script>
