<template>
    <div class="register content">
        <div class="container">
            <v-form class="login-form" @submit.prevent="createOrga">
                <h2>Organisation erstellen</h2>
                <div class="form-group" :class="{ 'form-group--error': $v.location.$error }">
                    <label for="vorname">
                        <v-text-field v-model="location" name="location" type="text" label="Ort" :error-messages="locationError"/>
                    </label>
                </div>
                <div class="form-group" :class="{ 'form-group--error': $v.location.$error }">
                    <label for="name">
                        <v-text-field v-model="name" name="name" type="text" label="Name" :error-messages="nameError"/>
                    </label>
                </div>
                <div class="form-group" :class="{ 'form-group--error': $v.party.$error }"> 
                    <label for="party">
                        <v-text-field v-model="party" name="party" type="text" label="Partei" :error-messages="partyError"/>
                    </label>
                </div>
                <div class="text">
                    Jede erstellte Organisation enthält eine Test-Kampagne <br> <br>
                    Diese umfasst: <br>
                    25 Plakate <br>
                    2 Nutzer
                </div>
                <v-divider></v-divider>
                <v-btn
                    rounded
                    color="primary"
                    dark
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                    >
                    Erstellen
                </v-btn>
            </v-form>
        </div>
    </div>
</template>
<script>

import {required} from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            location: '',
            name: '',
            party: '',
            loading: false,
            timerId: null,
        }
    },
    validations:{
        location:{
            required,
        },
        name:{
            required,
        },
        party:{
            required,
        },
    },
    computed:{
        locationError(){
            const errors = []
            if (!this.$v.location.$dirty)
                return errors
            !this.$v.location.required && errors.push('Bitte gib einen Ort an')
            return errors
        },
        nameError(){
            const errors = []
            if (!this.$v.name.$dirty)
                return errors
            !this.$v.name.required && errors.push('Bitte gib einen Namen an')
            return errors
        },
        partyError(){
            const errors = []
            if (!this.$v.party.$dirty)
                return errors
            !this.$v.party.required && errors.push('Bitte gib eine Partei an')
            return errors
        },
        userOrgas(){
            return this.$store.getters['orga/userOrgas'];
        },
    },
    methods: {
        createOrga(){
            this.$v.$touch();
            this.error='';
            if(!this.$v.$invalid){
                this.loading = true;
                let orgaUpdate = {
                    location: this.location,
                    name: this.name,
                    party: this.party,
                };
                this.$store.dispatch('orga/createOrga',orgaUpdate).then(() => {
                    this.timerId = setInterval(this.pushNext,5000); 
                })
            }
        },
        pushNext(){
            this.loading = false;
            clearInterval(this.timerId);
            this.timerId = null;
            this.$router.push('/orgaSelect');
        }
    }
}
</script>
<style lang="less" scoped>
.register{
    background: rgb(240,240,240);
    .container{
    max-width: 400px;
    width: 30%;
    height: auto;
    max-height: 80%;
    background-color: #EFF0F1;
    margin: 2rem auto 2rem auto;
    box-shadow: 5px 5px 9px 0px rgba(0,0,0,0.27);
    border-radius: 5px;
    form{
        margin: 0 auto;
        padding: 1rem;
        h2{
            margin: 0;
        }
        .error--text {
            color: rgb(255, 0, 0) !important;
        }
        label {
            padding: 0.5rem;
            display: block;
            text-align: left;
            span {
                font-size: 1rem;
                font-weight: bold;
                line-height: 2rem;
            }
            input {
                width: 100%;
                border-radius: 1rem;
                border: none;
                height: 2rem;
            }
        }
        .text{
            padding-bottom: 15px;
        }
        button {
            padding: 0 0.5rem 0 0.5rem;
            width: 95%;
            background: rgb(255,188,143);
            background: linear-gradient(315deg, rgba(255,188,143,1) 0%, rgba(255,143,158,1) 100%);
            color: white;
            height: 2.5rem;
            border: none;
            border-radius: 5px;
            font-size: 1.25rem;
            font-weight: bold;
            margin-top: 1rem;
            text-transform: none;
            letter-spacing: 1px; 
        }
        p {
            color: red;
            font-weight: bold;
        }
    }
}
}

@media screen and (max-width: 768px) {
  .register {
    .container{
      width: 90%;
      max-width: 90%;
      height: calc(100% - 60px);
      margin: 1rem auto;
    }
  }
}
</style>