<template>
  <div class="login content">
    <div class="container">
      <v-form class="login-form" @submit.prevent="login" lazy-validation>
        <h2>Anmelden</h2>
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.email.$error }"
        >
          <label for="email">
            <v-text-field
              v-model="email"
              name="email"
              type="email"
              label="E-Mail"
              :error-messages="mailError"
            />
          </label>
        </div>
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.password.$error }"
        >
          <label for="password">
            <v-text-field
              v-model="password"
              name="password"
              type="password"
              label="Passwort"
              :error-messages="passwordError"
            />
          </label>
        </div>
        <v-btn
          rounded
          color="primary"
          dark
          type="submit"
          :loading="loading"
          :disabled="loading"
        >
          Anmelden
        </v-btn>
      </v-form>
      <v-divider></v-divider>
      <div class="social-login">
        <v-btn
          rounded
          id="google"
          @click="loginGoogle"
          :loading="googleLoading"
          :disabled="googleLoading"
        >
          Mit Google Anmelden
        </v-btn>
        <v-btn
          rounded
          id="apple"
          @click="loginApple"
          :loading="appleLoading"
          :disabled="appleLoading"
        >
          Mit Apple Anmelden
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="register">
        <p>Noch kein Konto?</p>
        <router-link to="/register">Hier Registrieren</router-link>
      </div>
      <div class="pwreset">
        <router-link to="/passwordReset">Passwort zurücksetzen</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    mailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required &&
        errors.push("Bitte gib eine E-Mail Adresse an!");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push("Bitte gib ein Passwort an!");
      return errors;
    },
    providerLoading() {
      let load = this.$store.getters["user/providerLoading"];
      return load;
    },
    appleLoading() {
      return this.$store.getters["user/appleLoading"];
    },
    googleLoading() {
      return this.$store.getters["user/googleLoading"];
    },
  },
  methods: {
    async loginGoogle() {
      this.$store.commit("user/setGoogleLoading", true);
      let res = await this.$store.dispatch("user/loginGoogle");
      if (res == -1) {
        this.$swal({
          title: "Bitte versuche es später noch einmal",
          text: "Es ist ein Fehler aufgetreten",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-confirm",
          },
          confirmButtonText: "OK",
          buttonsStyling: false,
        });
        this.$store.commit("user/setGoogleLoading", false);
      }
    },
    async loginApple() {
      this.$store.commit("user/setAppleLoading", true);
      let res = await this.$store.dispatch("user/loginApple");
      if (res == -1) {
        this.$swal({
          title: "Bitte versuche es später noch einmal",
          text: "Es ist ein Fehler aufgetreten",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-confirm",
          },
          confirmButtonText: "OK",
          buttonsStyling: false,
        });
        this.$store.commit("user/setAppleLoading", true);
      }
    },
    async login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        let res = await this.$store.dispatch("user/login", {
          email: this.email,
          password: this.password,
        });
        this.loading = false;
        if (res === 0) {
          this.$swal({
            title: "Das Konto wurde deaktiviert",
            text: "Wende dich bitte an den Support",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            buttonsStyling: false,
          });
        } else if (res === -1) {
          this.$swal({
            title: "Benutzername und/oder Passwort inkorrekt",
            icon: "error",
            iconColor: "rgba(255, 143, 158)",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
      } else {
        if (!this.$v.email.required && this.$v.$dirty) {
          this.mailError;
        }
        if (!this.$v.password.required && this.$v.$dirty) {
          this.passwordError;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>

.login {
  background: rgb(240, 240, 240);
  .container {
    max-width: 400px;
    width: 30%;
    background-color: #eff0f1;
    margin: 2rem auto 2rem auto;
    max-height: 80%;
    box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
    form {
      margin: 0 auto;
      padding: 1rem;
      h2 {
        margin: 0;
      }
      label {
        .error--text {
          color: rgb(255, 0, 0) !important;
        }
        padding: 0.5rem;
        display: block;
        text-align: left;
        span {
          font-size: 1rem;
          font-weight: bold;
          line-height: 2rem;
        }
        input {
          width: 100%;
          border-radius: 1rem;
          border: none;
          height: 2rem;
        }
      }
      button {
        padding: 0 0.5rem 0 0.5rem;
        width: 95%;
        background: rgb(255, 188, 143);
        background: linear-gradient(
          315deg,
          rgba(255, 188, 143, 1) 0%,
          rgba(255, 143, 158, 1) 100%
        );
        color: white;
        height: 2.5rem;
        border: none;
        border-radius: 5px;
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 1rem;
        text-transform: none;
        letter-spacing: 1px;
      }
    }
    .social-login {
      padding: 0.5rem 1rem 0.5rem 1rem;

      button {
        display: block;
        height: 2.5rem;
        width: 100%;
        border-radius: 5px;
        margin: 0.5rem 0;
        font-size: 1rem;
        padding: 0 1rem;
        text-transform: none;
        letter-spacing: 0px;
      }
      #google {
        color: #757575;
        background-color: white;
      }
      #apple {
        color: white;
        background-color: black;
        border: none;
      }
    }
    .register {
      padding-bottom: 1rem;
      padding-top: 1rem;
      a {
        color: rgba(255, 143, 158, 1);
      }
    }
    .pwreset{
      a {
        color: rgba(255, 143, 158, 1);
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    .container{
      width: 90%;
      max-width: 90%;
      height: calc(100% - 60px);
      margin: 1rem auto;
    }
  }
}
</style>
