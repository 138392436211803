<template>
      <v-card class="container"> 
        <h1>Kampagnen-Status</h1>
        <div id="chart" v-if="sumPlakate != 0">
          <apexchart type="donut" width="300" :options="chartOptions" :series="series"></apexchart>
        </div>
        <div v-else>
          <br/>
          <p>Plane oder hänge Plakate um den Status deiner Kampagne verfolgen zu können.</p>
          <br/> 
        </div>
        <p>Gesamt: {{sumPlakate}}</p>
        <p>Maximum: {{campaignInfo.maxPlakate}}</p>
      </v-card>
</template>

<script>

export default ({
  name: 'CampaignContainer',
  props: ['selectedCampaign'],
  data() {
    return {
      series: [0,0,0,0],
      chartOptions: {
        chart: {
          width: 300,
          type: 'donut',
        },
        plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              //offsetY: 10
            }
          },
          grid: {
            padding: {
              bottom: -30
            }
          },
        labels: ['Geplant', 'Aufgehängt', 'Beschädigt', 'Abgehängt'],
        dataLabels: {
          enabled: false,
        },
        colors: ['#FFA500', '#00FF00', '#FF0000', '#BEBEBE'],
        responsive: [{
          breakpoint: 300,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  computed: {
    getOrgaMember() {
      return this.$store.getters["orga/orgaMember"];
    },
    campaignInfo: function(){
      return this.$store.getters['orga/campaign/campaignInfo'];
    },
    sumPlakate(){
      return this.campaignInfo.plakateGeplant + this.campaignInfo.plakateAufgehaengt + this.campaignInfo.plakateBeschaedigt + this.campaignInfo.plakateAbgehaengt;
    }
  },
  watch: {
    campaignInfo: function(){
      this.series = [
        this.campaignInfo.plakateGeplant,
        this.campaignInfo.plakateAufgehaengt,
        this.campaignInfo.plakateBeschaedigt,
        this.campaignInfo.plakateAbgehaengt
      ];
    },
  },
  created: function(){
    let campaignInfo = this.$store.getters['orga/campaign/campaignInfo'];
    if( campaignInfo && Object.keys(campaignInfo).length !== 0){
      this.series = [
        campaignInfo.plakateGeplant,
        campaignInfo.plakateAufgehaengt,
        campaignInfo.plakateBeschaedigt,
        campaignInfo.plakateAbgehaengt
      ];
    }
  },
  methods:{
    pushSettings(){
      this.$store.commit("setSettingsState", 'campaignSettings');
      this.$router.push('/settings');
    },
  },
})
</script>