import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './utils/firebase'
import Vuelidate from "vuelidate";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vuetify from '@/utils/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css' 
import VueApexCharts from 'vue-apexcharts'


Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueApexCharts);
library.add([faCog,faChevronLeft,faChevronRight]);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('apexchart', VueApexCharts)

let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
      created(){
        if(user){  
          let timerId = store.getters['emailVerificationTimer'];
          if(!user.emailVerified && !timerId){
            this.getUserEmailVerified(user);
          }
          const res = this.$route.query.paymentRes;
          if(res === 'cancel'){ 
            this.paymentResult.cancel = true;
            store.commit('user/setPaymentResult', this.paymentResult);
            router.push('/paymentResult');
          }
          else if(res === 'success'){
            this.paymentResult.success = true;
            store.commit('user/setPaymentResult', this.paymentResult);
            router.push('/paymentResult');
          }else{
            store.dispatch('reloadSite', user);
          }
        }
        else{
          store.commit('user/setGoogleLoading', false);
          store.commit('user/setAppleLoading', false);          
        }
      },
      data(){
        return{
          timerId: false,
          oldOrgaTuple: false,
          oldCampaignTuple: false,
          paymentResult: {
            success: false,
            cancel: false,
          },
        }
      },
      computed: {
        user(){
          return store.getters['user/user'];
        },
        selectedOrgaTuple(){
          return store.getters['orga/selectedOrga'];
        },
        selectedOrgaId(){
          let orgaTuple = store.getters['orga/selectedOrga'];
          return orgaTuple.orga.id;
        },
        selectedCampaignId(){
          let campaignTuple = store.getters['orga/campaign/selectedCampaign'];
          return campaignTuple.campaign.id;
        },
        selectedCampaignTuple(){
          return store.getters['orga/campaign/selectedCampaign'];
        },
        userCampaigns(){
          return store.getters['orga/campaign/userCampaigns'];
        },
        userOrgas(){
          return store.getters['orga/userOrgas'];
        },
        fbUser(){
          return store.getters['firebaseUser'];
        }
      },
      watch:{
        selectedOrgaId: function(){
          if(this.selectedOrgaTuple.orga == false && router.currentRoute.path != '/orgaSelect'){
            router.push('/orgaSelect').catch((error) => this.handleError(error));  
          }else if(this.selectedOrgaTuple.orga != false && router.currentRoute.path != '/campaignSelect'){
            router.push('/campaignSelect').catch((error) => this.handleError(error));  
            //store.dispatch('orga/initGetSelectedOrga', this.selectedOrgaTuple.orga);
          }
        },
        selectedCampaignId: function(){
          if(this.selectedOrgaTuple.orga != false && this.selectedCampaignTuple.campaign == false 
            && router.currentRoute.path != '/campaignSelect'){
            router.push('/campaignSelect').catch((error) => this.handleError(error)); 
          }else if(this.selectedOrgaTuple.orga != false && router.currentRoute.path != '/'){
            router.push('/').catch((error) => this.handleError(error));  
          }
        },
        user: function(){
          if(!this.user.isOnboarded && router.currentRoute.path != '/onboarding'){
            router.push('/onboarding').catch((error) => this.handleError(error)); 
          }
          if(this.user.isOnboarded){
            if(!this.fbUser.emailVerified && router.currentRoute.path != '/emailVerification'){
              router.push('/emailVerification').catch((error) => this.handleError(error)); 
            }
            else if((this.selectedOrgaTuple.user.id != this.user.id || this.selectedOrgaTuple.orga == false) 
              && router.currentRoute.path != '/orgaSelect'){
                store.commit('orga/setSelectedOrga', {user: this.user, orga: false});
                store.commit('orga/campaign/setSelectedCampaign', {orga: false, campaign: false});
                router.push('/orgaSelect').catch((error) => this.handleError(error));  
            }else if(this.selectedOrgaTuple.orga != false){
              if((this.selectedOrgaTuple.user.id == this.user.id && this.selectedCampaignTuple.orga.id != this.selectedOrgaTuple.orga.id)
                && router.currentRoute.path != '/campaignSelect'){
                  store.dispatch('orga/initGetSelectedOrga', this.selectedOrgaTuple.orga);
                  router.push('/campaignSelect').catch((error) => this.handleError(error));  
              }else if((this.selectedOrgaTuple.user.id == this.user.id && this.selectedCampaignTuple.orga.id == this.selectedOrgaTuple.orga.id) 
                && router.currentRoute.path != '/'){
                  store.dispatch('orga/initGetSelectedOrga', this.selectedOrgaTuple.orga);
                  store.dispatch('orga/campaign/initGetSelectedCampaign', this.selectedCampaignTuple.campaign);
                  router.push('/').catch((error) => this.handleError(error));
              }
            }
          } 
        },
        userCampaigns: function(){
          if(user){
            let included = false;
            for(let campaign of this.userCampaigns){
              if(campaign.id == this.selectedCampaignTuple.campaign.id){included = true;} 
            }
            if(!included){
              store.commit('orga/campaign/setSelectedCampaign', {orga: false, campaign: false});
            }
          }
        },
        userOrgas: function(){
          if(user){
            let included = false;
            for(let orga of this.userOrgas){
              if(orga.id == this.selectedOrgaTuple.orga.id){included = true;} 
            }
            if(!included){
              store.commit('orga/setSelectedOrga', {user: this.user, orga: false});
              store.commit('orga/campaign/setSelectedCampaign', {orga: false, campaign: false});
            }
          }
        },
        fbUser: function(){
          if(this.fbUser){
            if(!this.fbUser.emailVerified){
              this.getUserEmailVerified(this.fbUser);
            }
          }
        }
      },
      methods: {
        handleError(error){
          if(error.length==0){
            console.log("Error Redirect");
          }
        },
        getUserEmailVerified(user){
          var timerId = setInterval(function(){
            user.reload();
            if(user.emailVerified){
              store.commit('setFirebaseUser', user);
              router.push('/orgaSelect').catch((error) => this.handleError(error));
              clearInterval(timerId);
              store.commit('setEmailVerificationTimer', false);
            }
          },5000, user); 
          store.commit('setEmailVerificationTimer', timerId);
        }
      }, 
    }).$mount('#app')    
  }
});
