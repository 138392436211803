<template>
    <div class="login content">
        <div class="container">
            <h2>Organisation auswählen</h2>
            <div class="orgaList">
                <v-list-item v-for="orga in userOrgas" :key="orga.id" @click="selectOrga(orga)">
                    <v-list-item-content>
                        <v-list-item-title >{{orga.name}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <div class="orgaText" v-if="userOrgas.length<1">Du bist leider in noch keiner Organisation! 
                    Erstelle eine oder lasse dich mit Hilfe deines Nutzernamen <span class="username">@{{user.nickname}}</span> in eine Organisation einladen!
                </div>
                
            </div>
            <v-divider></v-divider>
            <v-btn to="/createOrga" class="button">Organisation <br/>erstellen</v-btn>
        </div>
    </div>
</template>
<script>

export default {
    name: "OrgaSelection",
    data() {
        return {
			
        }
	},
    computed:{
        userOrgas(){
            return this.$store.getters['orga/userOrgas'];
        },
        user(){
            return this.$store.getters['user/user'];
        },
    },
    methods:{
        selectOrga(orga){
            this.$store.dispatch('orga/initGetSelectedOrga', orga);
        }
    },
    created: function () {
        //this.$store.dispatch('orga/getUserOrgas');
    },
}
</script>
<style lang="less" scoped>
.login{
    background: rgb(240,240,240);
    .container{
    max-width: 400px;
    width: 30%;
    max-height: 80%;
    background-color: #EFF0F1;
    margin: 2rem auto 2rem auto;
    box-shadow: 5px 5px 9px 0px rgba(0,0,0,0.27);
    border-radius: 5px;
    .orgaList{
        margin: 0 auto;
        padding: 1rem;
        h2{
            margin: 0;
        }
        .orgaText{
            padding: 0.5rem;

        }
        .username{
            font-weight: 700;
        }  
    }
    .button {
            white-space: normal;
            padding: 0 0.5rem 0 0.5rem;
            width: 95%;
            background: rgb(255,188,143);
            background: linear-gradient(315deg, rgba(255,188,143,1) 0%, rgba(255,143,158,1) 100%);
            color: white;
            height: 3rem;
            border: none;
            border-radius: 5px;
            font-size: 1.25rem;
            font-weight: bold;
            margin-top: 1rem;
            text-transform: none;
            letter-spacing: 0px; 
        }
}
}
@media screen and (max-width: 768px) {
  .login {
      &.content{
          min-height: calc(100% - 100px);
          height: calc(100vh - 100px);
      }
    .container{
      width: 90%;
      max-width: 90%;
      height: calc(100% - 60px);
      margin: 1rem auto;
      
    }
  }
}
</style>