<template>
  <div>
    <div class="content" fluid>
      <div class="dashboard">
        <personal-container v-bind:user="user" />
        <orga-container v-bind:selectedOrga="selectedOrga.orga" />
        <current-campaign-container
          v-bind:selectedCampaign="selectedCampaign.campaign"
        />
        <campaign-container
          v-bind:selectedCampaign="selectedCampaign.campaign"
        />
        <orga-selector />
        <map-container />
      </div>
    </div>
  </div>
</template>

<script>
import OrgaContainer from "@/components/dashboard/OrgaContainer.vue";
import PersonalContainer from "@/components/dashboard/PersonalContainer.vue";
import OrgaSelector from "@/components/dashboard/OrgaSelector.vue";
import MapContainer from "@/components/dashboard/MapContainer.vue";
import CampaignContainer from "@/components/dashboard/CampaignContainer.vue";
import CurrentCampaignContainer from "../components/dashboard/CurrentCampaignContainer.vue";

export default {
  components: {
    OrgaContainer,
    PersonalContainer,
    OrgaSelector,
    MapContainer,
    CampaignContainer,
    CurrentCampaignContainer,
  },
  name: "Home",
  data() {
    CurrentCampaignContainer;
    return {};
  },
  created: function(){
    let demoToast = this.$store.getters['demoToast'];
    if(!demoToast && this.selectedCampaign.campaign.id == 'DEMOCAMPAIGN'){
      this.$store.commit('setDemoToast', true);
      this.$swal({
        toast: true,
        position: 'top',
        timer: 15000,
        timerProgressBar: true,
        title: 'Du befindest dich in einer Testkampagne!',
        text: 'Möchtest du eine Kampagne mit mehr Kapazitäten erstellen?',
        showCancelButton: true,
        confirmButtonText: "Ja",
        customClass: {
          confirmButton: "toastbtn toastbtn-confirm",
          cancelButton: "toastbtn toastbtn-cancel",
        },
        buttonsStyling: false,
        cancelButtonText: "Nein",
      }).then((res) => {
        if (res.isConfirmed) {
          this.$router.push('/createCampaign');
        }
      });
    } 
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    userOrgas() {
      return this.$store.getters["orga/userOrgas"];
    },
    selectedOrga() {
      return this.$store.getters["orga/selectedOrga"];
    },
    getPlakate() {
      return this.$store.getters["orga/campaign/plakate"];
    },
    getOrgaMember() {
      return this.$store.getters["orga/orgaMember"];
    },
    getAdminNumber() {
      return this.getOrgaMember.filter(
        (x) => x.role === "admin" || x.role === "creator"
      ).length;
    },
    selectedCampaign() {
      return this.$store.getters["orga/campaign/selectedCampaign"];
    },
    getUpdates() {
      let total = 0;
      this.$store.getters["orga/plakate"].forEach((plakat) => {
        total += plakat.history.length;
      });
      return total;
    },
  },
};
</script>

<style lang="less">
.content {
  background: rgba(240, 240, 240, 0.6);
  align-items: normal;
  .dashboard {
    margin: 5% auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 60%;
    .container {
      width: 400px;
      height: 250px;
      background-color: rgba(255, 255, 255, 0.9);
      margin: 1rem 0.5rem;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.27);
      transition: box-shadow 0.15s ease-in-out;
      &:hover {
        box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.5);
        transition: box-shadow 0.15s ease-in-out;
      }
      h1 {
        padding-top: 0.25rem;
        font-size: 1.75rem;
        margin-bottom: 2rem;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .content {
    height: auto;
    min-height: auto;
    .dashboard{
      width: 100%;
      display: flex;
      flex-direction: column;
      .container{
        max-width: 95%;
        margin: 1rem auto;
        width: 95%;
      }
    }
  }
}
</style>
