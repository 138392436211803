<template>
  <div class="login content">
    <div class="container">
      <h2>Kampagnen Auswahl</h2>
      <div class="campaignList">
        <div
          class="campaignText"
          v-if="
            testcampaign &&
            (userRoleOrga === 'admin' || userRoleOrga === 'creator')
          "
        >
          Wir bereiten aktuell deine Testkampagne vor. Bitte warte einen
          Moment... <br />
          <v-progress-circular
            indeterminate
            class="progress"
          ></v-progress-circular>
        </div>
        <div v-else>
          <v-list-item
            v-for="campaign in userCampaigns"
            :key="campaign.id"
            @click="selectCampaign(campaign)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ campaign.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div
            class="campaignText"
            v-if="userCampaigns.length < 1 && userRoleOrga !== 'creator'"
          >
            Du bist leider in noch keiner Kampagne! Lasse dich mit Hilfe deines
            Nutzernamen <span class="username">@{{ user.nickname }}</span> in
            eine Kampagne deiner Organisation einladen!
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <v-btn @click="pushCampaignCreate" class="button"
        >Kampagne erstellen</v-btn
      >
      <v-btn @click="back" class="back" text>Zurück</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "CampaignSelection",
  data() {
    return {};
  },
  computed: {
    testcampaign() {
      let loading = true;
      let campaigns = this.$store.getters["orga/campaign/userCampaigns"];
      campaigns.length < 1 ? (loading = true) : (loading = false);
      return loading;
    },
    userCampaigns() {
      return this.$store.getters["orga/campaign/userCampaigns"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    orgaMember() {
      return this.$store.getters["orga/orgaMember"];
    },
    userRoleOrga() {
      let role = "member";
      this.orgaMember.forEach((userInOrga) => {
        if (this.user.id === userInOrga.id) {
          role = userInOrga.role;
        }
      });
      return role;
    },
  },
  methods: {
    selectCampaign(campaign) {
      this.$store.dispatch("orga/campaign/initGetSelectedCampaign", campaign);
    },
    pushCampaignCreate() {
      if (this.userRoleOrga == "member") {
        this.$swal({
          title: "Fehlende Berechtigung!",
          text: "Du musst Ersteller oder Administrator der Organisation sein um Kampagnen erstellen zu können",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-confirm",
          },
          confirmButtonText: "OK",
          buttonsStyling: false,
        });
      } else {
        this.$router.push("/createCampaign");
      }
    },
    back() {
      this.$store.commit("orga/setSelectedOrga", {
        user: this.user,
        orga: false,
      });
      this.$router.push("/orgaSelect");
    },
  },
  created: function () {
    //this.$store.dispatch('orga/campaign/getUserCampaigns');
  },
};
</script>
<style lang="less" scoped>
.login {
  background: rgb(240, 240, 240);
  .container {
    max-width: 400px;
    width: 30%;
    max-height: 80%;
    background-color: #eff0f1;
    margin: 2rem auto 2rem auto;
    box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
    .campaignList {
      margin: 0 auto;
      padding: 1rem;
      .campaignText {
        padding: 0.5rem;
      }
      .username {
        font-weight: 700;
      }
      .progress {
        color: rgba(255, 143, 158);
        margin-top: 20px;
      }
    }
    .button {
      white-space: normal;
      padding: 0 0.5rem 0 0.5rem;
      width: 95%;
      background: rgb(255, 188, 143);
      background: linear-gradient(
        315deg,
        rgba(255, 188, 143, 1) 0%,
        rgba(255, 143, 158, 1) 100%
      );
      color: white;
      height: 3rem;
      border: none;
      border-radius: 5px;
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 1rem;
      text-transform: none;
      letter-spacing: 1px;
    }
    .back {
      margin: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .login{
    .container{
      width: 90%;
      min-height: calc(100vh - 100px);
    }
  }
}
</style>