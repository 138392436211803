import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import orga from './modules/orga'
import createPersistedState from "vuex-persistedstate";
import * as fb from '../utils/firebase'
import router from '../router/index'

Vue.use(Vuex)

const store =  new Vuex.Store({
  modules:{
    user,
    orga,
  },
  state: {
    settingsState: 'profil',
    firebaseUser: null,
    emailVerficationTimer: false,
    demoToast: false,
  },
  mutations: {
    setSettingsState(state, val){
      state.settingsState = val;
    },
    setFirebaseUser(state, val){
      state.firebaseUser = val;
    },
    setEmailVerificationTimer(state, val){
      state.emailVerficationTimer = val;
    },
    setDemoToast(state, val){
      state.demoToast = val;
    },
  },
  getters: {
      settingsState: state => {
        return state.settingsState
      },
      firebaseUser: state => {
        return state.firebaseUser;
      },
      emailVerificationTimer: state => {
        return state.emailVerficationTimer;
      },
      demoToast: state => {
        return state.demoToast;
      },
  },
  actions: {
    async logout({ commit, getters }) {
      let timerId = getters['emailVerificationTimer'];
      clearInterval(timerId);
      commit('setEmailVerificationTimer', false);
      commit('setFirebaseUser', null);
      commit('setDemoToast', false);
      try{   
        commit('user/resetUserListener');
        commit('orga/resetSelOrgaListener');
        commit('orga/resetUserOrgasListener');
        commit('orga/resetOrgaMemberListener');
        commit('orga/campaign/resetUserCampaignsListener');
        commit('orga/campaign/resetSelCampaignListener');
        commit('orga/campaign/resetCampaignMemberListener');
        commit('orga/campaign/resetCampaignInfoListener');
        commit('orga/campaign/resetPlakatListener');
      }catch(e){
        console.error("Logout-Fehler");
      }
      commit('orga/campaign/setPlakate', []);
      commit('orga/setOrgaMember', []);
      commit('user/setUserProfile', {});
      commit('orga/campaign/setCampaignInfo',{});
      commit('orga/campaign/setCampaignMember',[]);
      await fb.auth.signOut()
      router.push('/login')
    },
    async reloadSite({dispatch, commit}, fb_user){
      var result = await fb.auth.getRedirectResult();
      const redirect_user = result.user;
      try {
        commit('user/resetUserListener');
      } catch (error) {
        console.error("Reset Reload Error");
      }
      // Ist null wenn kein Redirect über Google oder Apple erfolgt ist.
      if(redirect_user != null){
        dispatch('user/providerRedirect', redirect_user)
      }else{
        await dispatch('user/userStream', fb_user);
        dispatch('initStreamsDirect', fb_user);
      }   
    },
    async initStreamsDirect({getters, dispatch, commit}, fb_user){
      try{
        commit('orga/resetSelOrgaListener');
      }catch(e) {console.error("Reset Error ");}
      try{
        commit('orga/campaign/resetSelCampaignListener')
      }catch(e) {console.error("Reset Error");}
      let selectedOrga = getters['orga/selectedOrga'];
      if(selectedOrga.orga != false && selectedOrga.user.id == fb_user.uid) {
        dispatch('orga/initGetSelectedOrga', selectedOrga.orga);
        let selectedCampaign = getters['orga/campaign/selectedCampaign'];
        if(selectedCampaign.orga != false && selectedCampaign.orga.id == selectedOrga.orga.id){
          dispatch('orga/campaign/initGetSelectedCampaign', selectedCampaign.campaign);
        }
      }
    },
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
});

export default store;