<template>
  <div>
    <v-card class="orgas container">
      <h1>Organisation / Kampagne wechseln</h1>
      <v-btn @click.prevent="pushSettings(true)" class="button">Organisation wechseln</v-btn>
      <v-btn @click.prevent="pushSettings(false)" class="button">Kampagne wechseln</v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "OrgaSelector",
  methods:{
    pushSettings(set){
      if(set){
        this.$store.commit("setSettingsState", 'orgas');
      }
      else{
        this.$store.commit("setSettingsState", 'campaigns');
      } 
      this.$router.push('/settings');
    },
  },
};
</script>
<style lang="less" scoped>
.orgas {
  .button {
    padding: 0 0.5rem 0 0.5rem;
    width: 95%;
    background: rgb(255,188,143);
    background: linear-gradient(315deg, rgba(255,188,143,1) 0%, rgba(255,143,158,1) 100%);
    color: white;
    height: 3rem;
    border: none;
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1rem;
    text-transform: none;
    letter-spacing: 0px; 
  }
}

</style>
