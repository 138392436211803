<template>
  <div class="content">
    <v-container class="container2">
      <div class="loader"></div>
      <div v-if="res.cancel">
        <h2>Der Vorgang wurde abgebrochen.</h2>
        <br/>
        <h3>Bei weiteren Problemen kontaktiere unseren Support: info@stactix.com.</h3>
        <br/>
        <p>Wir leiten dich auf das Dashboard weiter...</p>
    </div>
    <div v-if="res.success">
        <h2>Vielen Dank für deinen Einkauf, wir leiten dich gleich auf das Dashboard weiter!</h2>
        <br/>
        <h3>Du kannst deine Kampagne danach über die Optionen anwählen.</h3>
    </div>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "PaymentResult",
  data() {
    return {
        res: null,
        intervallId: null,
    };
  },
  created: function(){
      const res = this.$store.getters['user/paymentResult'];
      this.res = res;
      this.intervallId = setInterval(this.redirect,10000);
  },
  computed: {
  },
  methods: {
      redirect(){
          const fb_user = this.$store.getters['firebaseUser'];
          clearInterval(this.intervallId);
          this.$store.commit('user/setPaymentResult', null);
          this.$store.dispatch('reloadSite', fb_user);
      }
  },
};
</script>

<style lang="less" scoped>
.container2 {
  width: clamp(400px, 90%, 1500px);
  height: 80%;
  background-color: #eff0f1;
  margin: 2rem auto 2rem auto;
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
  border-radius: 5px;
  .loader {
    position: relative;
    margin: 2rem auto 2rem auto;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid rgba(255, 143, 158, 1); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
}
</style>