<template>
  <div class="reset content">
    <div class="container">
      <v-form class="reset-form" @submit.prevent="reset" lazy-validation>
        <h2>Passwort zurücksetzen</h2>
        <br/>
        <p>Bitte gib deine E-Mail Adresse an. <br/> Wir senden dir anschließend einen Link um dein Passwort zurückzusetzen</p>
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.email.$error }"
        >
          <label for="email">
            <v-text-field
              v-model="email"
              name="email"
              type="email"
              label="E-Mail"
              :error-messages="mailError"
            />
          </label>
        </div>
        <v-btn
        class="submit"
          color="primary"
          dark
          type="submit"
          :loading="loading"
          :disabled="loading"
        >
          Senden
        </v-btn>
        <v-btn
          text
          to="/login"
        >
          Zurück
        </v-btn>
      </v-form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "PasswordReset",
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  validations: {
    email: {
      required,
    },
  },
  computed: {
    mailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required &&
        errors.push("Bitte gib eine E-Mail Adresse an!");
      return errors;
    },
  },
  methods: {
    async reset(){
        this.$v.$touch();
        if (!this.$v.$invalid) {
            this.loading = true;
            let res = await this.$store.dispatch("user/resetPassword", this.email);
            if(res == 1){
                this.$swal({
                    title: "Wir haben dir eine E-Mail zugesandt",
                    icon: "success",
                    customClass: {
                    confirmButton: "btn btn-confirm",
                    },
                    buttonsStyling: false,
                });
                this.loading = false;
                this.$router.push('/login');
            }else if(res == 0){
                this.$swal({
                    title: "Wir konnten deine E-Mail leider nicht finden",
                    icon: "error",
                    iconColor: "rgba(255, 143, 158)",
                    customClass: {
                    confirmButton: "btn btn-confirm",
                    },
                    confirmButtonText: "OK",
                    buttonsStyling: false,
                });
                this.loading = false;
            }else{
                this.$swal({
                    title: "Es ist ein Fehler aufgetreten",
                    icon: "error",
                    iconColor: "rgba(255, 143, 158)",
                    customClass: {
                    confirmButton: "btn btn-confirm",
                    },
                    confirmButtonText: "OK",
                    buttonsStyling: false,
                });
                this.loading = false;
            }
        }else{
            if (!this.$v.email.required && this.$v.$dirty) {
                this.mailError;
            }
        }
      }
  },
};
</script>
<style lang="less" scoped>

.reset {
  background: rgb(240, 240, 240);
  .container {
    max-width: 400px;
    width: 30%;
    background-color: #eff0f1;
    margin: 2rem auto 2rem auto;
    max-height: 80%;
    box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
    form {
      margin: 0 auto;
      padding: 1rem;
      h2 {
        margin: 0;
      }
      label {
        .error--text {
          color: rgb(255, 0, 0) !important;
        }
        padding: 0.5rem;
        display: block;
        text-align: left;
        span {
          font-size: 1rem;
          font-weight: bold;
          line-height: 2rem;
        }
        input {
          width: 100%;
          border-radius: 1rem;
          border: none;
          height: 2rem;
        }
      }
      .submit {
        margin: 0.5rem;
        padding: 0 0.5rem 0 0.5rem;
        width: 95%;
        background: rgb(255, 188, 143);
        background: linear-gradient(
          315deg,
          rgba(255, 188, 143, 1) 0%,
          rgba(255, 143, 158, 1) 100%
        );
        color: white;
        height: 2.5rem;
        border: none;
        border-radius: 5px;
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 1rem;
        text-transform: none;
        letter-spacing: 1px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .reset {
    .container{
      width: 90%;
      max-width: 90%;
      height: calc(100% - 60px);
      margin: 1rem auto;
    }
  }
}
</style>
